import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { webSocketActions } from "../../redux/actions/webSocketActions";
import { useViewDashboardsQuery } from "../../redux/api/dashboard-api/dashboard-api";
import { receive } from "../../redux/api/websocket-middleware";
import { useAppDispatch } from "../../redux/store/store";
import DashboardSideMenu from "../../shared/components/dashboard-side-menu/dashboard-side-menu";
import Tabs from "../../shared/components/tabs/tabs";
import WidgetMoreInfo from "../../shared/components/widget-more-info/widget-more-info";
import DashboardEditSideBar from "../../shared/components/workflow-edit-side-bar/workflow-edit-side-bar";
import { Dashboard, Widget } from "../../shared/interfaces/modals";
import WidgetTypes from "../../shared/utils/getWidgetTypes";
import styles from "./dashboards.module.scss";

const defaultDashboardValue: Dashboard = {
  id: "",
  label: "",
  widgets: [],
};

const Dashboards = () => {
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [showDashboardEditSidebar, setShowDashboardEditSidebar] =
    useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState<Dashboard>({
    ...defaultDashboardValue,
  });
  const [showDashboard, setShowDashboard] = useState(true);
  const [selectedWidget, setSelectedWidget] = useState<Widget>();

  const { data: dashboards } = useViewDashboardsQuery();
  const [sensorReadings, setSensorReadings] = useState<Record<string, number>>(
    {}
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(webSocketActions());

    const subscription = receive().subscribe((activity) => {
      const parsedData = activity;
      setSensorReadings(parsedData.sensorReadings);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [dispatch]);

  return (
    <Row>
      <DashboardEditSideBar
        show={showDashboardEditSidebar}
        selectedDashboard={selectedDashboard}
        onClose={() => {
          setShowDashboardEditSidebar(false);
        }}
      />
      <Col xs={12} className="">
        <Tabs />
      </Col>
      <Col xs={12} className={"pt-2 pt-md-4"}>
        <div className={`${styles.workflowContainer} d-block d-md-flex`}>
          <div className={styles.menu}>
            <DashboardSideMenu
              onShowDashboardSidebar={() => {
                setShowDashboardEditSidebar(true);
              }}
              setShowDashboard={setShowDashboard}
              setSelectedDashboard={setSelectedDashboard}
              selectedDashboard={selectedDashboard}
            />
          </div>
          <div className={`pt-2 pt-md-0 ${styles.content}`}>
            <WidgetMoreInfo
              show={showMoreInfo}
              onClose={() => setShowMoreInfo(false)}
              selectedWidget={selectedWidget}
            />
            <Row className="g-2 g-md-3">
              {selectedDashboard.widgets.map((w) => (
                <Col
                  key={w.id}
                  className={
                    showDashboard
                      ? "col-12 col-xxl-6"
                      : "col-12 col-lg-6 col-xxl-4"
                  }
                >
                  <WidgetTypes
                    widgetType={w.type}
                    widget={w}
                    onClick={(w, value) => {
                      setSelectedWidget(w);
                      setShowMoreInfo(value);
                    }}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Dashboards;
