import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { selectAuth } from "../redux/slices/auth-slice";
import { Pages } from "./routes";

const AuthLayout = () => {
  const navigate = useNavigate();
  const auth = useSelector(selectAuth);

  useEffect(() => {
    if (auth.token) {
      navigate(Pages.dashboards);
    }
  }, [auth.token, navigate]);

  return (
    <Container fluid className="content p-2 p-md-4">
      <Outlet />
    </Container>
  );
};

export default AuthLayout;
