import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useAddAlertTemplateMutation,
  useViewAlertTemplateQuery,
} from "../../redux/api/alert-api/alert-api";
import testIcon from "../../shared/assets/images/lets-icons_check-fill.svg";
import AlertCard from "../../shared/components/alert-card/alert-card";
import AlertSideMenu from "../../shared/components/alert-side-menu/alert-side-menu";
import Tabs from "../../shared/components/tabs/tabs";
import AppModal from "../../shared/ui-elements/app-modal/app-modal";
import Button from "../../shared/ui-elements/button/button";
import Select from "../../shared/ui-elements/select/select";
import TextField from "../../shared/ui-elements/text-field/text-field";
import { emailRegex, mobileNumberRegex } from "../../shared/utils/regex";
import styles from "./manage-alerts.module.scss";

type Inputs = {
  name: string;
  medium: string;
  to: string;
};

const ManageAlerts = () => {
  const { data: alerts } = useViewAlertTemplateQuery();

  const [showModal, setShowModal] = useState(false);
  const [available, setAvailable] = useState<"yes" | "no" | "-">("-");
  const [addAlert, { isLoading }] = useAddAlertTemplateMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<Inputs>();

  const medium = watch("medium");

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const x = {
        name: data.name,
        communicationMedium: data.medium,
        to: data.to,
      };

      await addAlert(x).unwrap();
      toast.success("Add alert succuss fully.");
      reset();
      setShowModal(false);
    } catch (err: any) {
      reset();
      toast.error("Failed to save the alert.");
    }
  };

  return (
    <Container fluid className="p-0">
      <Row className="align-items-center justify-content-between">
        <Col xs={12} md={9}>
          <Tabs />
        </Col>
        <Col xs={12} md={3} xl={2} className="mt-3 mt-md-0">
          <Button
            text="New Alert"
            onClick={() => {
              setShowModal(true);
            }}
            variant="Primary"
            borderRadius={24}
            type="button"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={"pt-2 pt-md-4"}>
          <div className={`${styles.alertContainer} d-block d-md-flex`}>
            <div className={styles.menu}>
              <AlertSideMenu />
            </div>
            <Container
              fluid
              className={`${styles.content} py-3 px-3 p p-md-4 mt-2 mt-md-0`}
            >
              <Row>
                <Col className={styles.title}>Manage Alert</Col>
              </Row>
              <Row className="mt-4">
                {alerts?.map((a) => (
                  <Col xs={12} className="mb-3">
                    <AlertCard alert={a} />
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
      <AppModal
        show={showModal}
        onClose={() => setShowModal(false)}
        width={523}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <Row className="justify-content-center mt-2">
              <Col xs="auto" className={styles.txt}>
                New Alert Template
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <TextField
                  name="name"
                  placeholder=""
                  label="Alert Name"
                  register={register("name", {
                    required: "Please enter a alert name",
                  })}
                  errors={errors}
                />
              </Col>
              <Col xs={12} className="mt-4">
                <Select
                  label="Medium"
                  options={[
                    { value: "Email", id: "EMAIL" },
                    { value: "Whats app", id: "WHATSAPP" },
                  ]}
                  inputProps={{ ...register("medium", { required: true }) }}
                />
              </Col>
              <Col xs={12} className="mt-4">
                <TextField
                  name="to"
                  placeholder=""
                  label="To"
                  register={register("to", {
                    required: `Please enter a ${
                      medium === "EMAIL" ? "email" : "mobile Number"
                    }`,
                    pattern: {
                      value:
                        medium === "EMAIL" ? emailRegex : mobileNumberRegex,
                      message: `Please enter a ${
                        medium === "EMAIL"
                          ? "valid email"
                          : "valid mobile Number"
                      }`,
                    },
                  })}
                  type={medium === "EMAIL" ? "email" : "number"}
                  errors={errors}
                />
              </Col>
              <Col
                xs={12}
                className="mt-4 d-flex align-items-center"
                style={{
                  color: available === "yes" ? "#00C22B" : "red",
                  fontSize: "20px",
                  fontWeight: "400",
                }}
              >
                {available !== "-" && (
                  <>
                    {available === "yes" ? (
                      <>
                        <img src={testIcon} alt="" />
                        Test notification has been sent
                      </>
                    ) : (
                      <>Test notification has not been sent</>
                    )}
                  </>
                )}
              </Col>
            </Row>
            <Row className="mt-5 justify-content-center">
              <Col xs="auto">
                <Button
                  text="Test"
                  onClick={async () => {
                    // const id = getValues("id");
                    // const available = (await check(id).unwrap()).isAvailable;
                    setAvailable("yes");
                  }}
                  variant="Outline"
                  borderRadius={24}
                  type="button"
                  padding="12px 90px"
                />
              </Col>
              <Col xs="auto">
                <Button
                  text="Add"
                  variant="Primary"
                  borderRadius={24}
                  type="submit"
                  padding={`12px 90px`}
                  isLoading={isLoading}
                />
              </Col>
            </Row>
          </Container>
        </form>
      </AppModal>
    </Container>
  );
};

export default ManageAlerts;
