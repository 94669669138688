export function enumToObject<A, B>(
  _enum: A,
  converter: (value: string) => B
): B[] {
  return Object.entries(_enum as any)
    .filter((e) => !isNaN(e[0] as any))
    .map((e) => converter(e[1] as any));
}

export function capitalizeFirstLetter(value: string) {
  const rev = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  return rev.replace(/_/g, " ");
}

export function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
