import { Col, Container, Row } from "react-bootstrap";
import arrow from "../../../assets/images/arrow.svg";
import dropDown from "../../../assets/images/arrow_drop_down.svg";
import { ReadingUnit } from "../../../enums";
import IconButton from "../../../ui-elements/icon-button/icon-button";
import MaterialIcon from "../../../ui-elements/material-icon/material-icon";
import OilTank from "../../../ui-elements/oil-tank/oil-tank";
import { getReadingTypes } from "../../../utils/getReadingTypes";
import styles from "./widget-tank.module.scss";

interface IProps {
  title: string;
  icon: any;
  value: number;
  unit: string;
  upload: number;
  download: number;
  onClick: () => void;
}

const WidgetTank = (props: IProps) => {
  return (
    <Container fluid className={`${styles.widgetContainer} p-4`}>
      <Row className="justify-content-between">
        <Col xs="auto">
          <div className={styles.title}>{props.title}</div>
          <Row className={`align-items-center ${styles.subTitle}`}>
            <Col xs={"auto"} className={"pe-0"}>
              Today
            </Col>
            <Col xs={"auto"} className={"ps-2"}>
              <img src={dropDown} alt={""} />
            </Col>
          </Row>
        </Col>
        <Col xs={"auto"}>
          <IconButton
            icon={arrow}
            width={60}
            height={40}
            background="#2F2A89"
            boxShadow="-4px 10px 22px 0px rgba(47, 42, 137, 0.42)"
            padding="8px 20px"
            borderRadius={16}
            onClick={() => {
              props.onClick();
            }}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={"auto"}>
          <OilTank value={(props.value / 10000) * 100} />
        </Col>
        <Col>
          <Row>
            <Col xs={12}>
              <IconButton
                padding={"8px"}
                icon={props.icon}
                width={40}
                height={40}
              />
            </Col>
          </Row>
          <Row className="align-items-end">
            <Col xs="auto" className={styles.value}>
              {props.value}
            </Col>
            <Col xs="auto" className={`ps-0 ${styles.unit}`}>
              {getReadingTypes(props.unit as ReadingUnit) || props.unit}
            </Col>
          </Row>
          <Row className="row-cols-auto ps-1">
            <Col>
              <Row className="align-items-center">
                <Col xs={"auto"} className="pe-0">
                  <MaterialIcon
                    icon="vertical_align_top"
                    size={16}
                    color="#383941"
                  />
                </Col>
                <Col xs={"auto"} className={`ps-1 ${styles.exchangeValue}`}>
                  {props.upload}
                </Col>
              </Row>
            </Col>
            <Col className="ps-4">
              <Row className="align-items-center">
                <Col xs="auto" className="pe-0">
                  <MaterialIcon
                    icon="vertical_align_bottom"
                    size={16}
                    color="#383941"
                  />
                </Col>
                <Col xs={"auto"} className={`ps-1 ${styles.exchangeValue}`}>
                  {props.download}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default WidgetTank;
