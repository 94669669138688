import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styles from "./chart.module.scss";

interface ChartData {
  time: string;
  value1: number;
  value2: number;
}

interface IProps {
  data: ChartData[];
  chartType: "Aria" | "Bar";
}

const Chart = (props: IProps) => {
  if (props.chartType === "Aria") {
    return (
      <ResponsiveContainer width="100%" height={500}>
        <AreaChart
          data={props.data}
          margin={{ top: 20, right: 30, bottom: 0, left: 0 }}
        >
          <defs>
            <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#4AC3FF" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#2C2F48" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#CB12F9" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#2C2F48" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" stroke="#4A4A4A" />
          <XAxis dataKey="time" stroke="#383941" interval={10} />
          <YAxis stroke="#383941" />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="linear"
            dataKey="value1"
            stroke="#4AC3FF"
            fillOpacity={1}
            fill="url(#colorUv1)"
            dot={{ fill: "#ffffff", stroke: "#4AC3FF", strokeWidth: 2, r: 4 }}
          />
          <Area
            type="linear"
            dataKey="value2"
            stroke="#EB75E1"
            fillOpacity={1}
            fill="url(#colorUv2)"
            dot={{ fill: "#ffffff", stroke: "#EB75E1", strokeWidth: 2, r: 4 }}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  } else {
    return (
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={props.data}
          barCategoryGap={5}
          barGap={20}
          margin={{ top: 20, right: 30, bottom: 0, left: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#4A4A4A" />
          <XAxis dataKey="time" stroke="#ccc" interval={10} />
          <YAxis stroke="#ccc" />
          <Tooltip content={<CustomTooltip />} cursor={{ fill: "#00000044" }} />
          <Bar dataKey="value1" fill="#4AC3FF" /> {/* Solid color for value1 */}
          <Bar dataKey="value2" fill="#EB75E1" /> {/* Solid color for value2 */}
        </BarChart>
      </ResponsiveContainer>
    );
  }
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
}

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltip}>
        <div>{`Time: ${label}`}</div>
        <div className={"mt-1"}>
          Dryer Temperature: {payload[0].value} &deg;C
        </div>
        <div className={"mt-1"}>
          Coconut kernel Feed Rate: {payload[1].value}
        </div>
        <div className={"mt-1"}>
          Critical - <span className={styles.critical}>No</span>
        </div>
      </div>
    );
  }
  return null;
};

export default Chart;
