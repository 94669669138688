import AddSensorRequestDTO from "../../../shared/interfaces/dtos/request-dtos/add-sensor-request-dto";
import UpdateGeneralSensorRequestDTO from "../../../shared/interfaces/dtos/request-dtos/update-general-sensor-request-dto";
import ViewGeneralSensorsResponseDTO from "../../../shared/interfaces/dtos/request-dtos/view-general-sensors-response-dto";
import UpdateGeneralSensorResponseDTO from "../../../shared/interfaces/dtos/response-dtos/update-general-sensor-response-dto";
import { Sensor } from "../../../shared/interfaces/modals";
import { rootApi } from "../api-manager";

// Define a service using a base URL and expected endpoints
export const sensorAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addSensor: builder.mutation({
      query: (sensor: AddSensorRequestDTO) => ({
        url: "/sensor/register",
        method: "POST",
        body: sensor,
      }),
      invalidatesTags: ["sensors"],
    }),
    updateSensor: builder.mutation<
      UpdateGeneralSensorResponseDTO,
      UpdateGeneralSensorRequestDTO
    >({
      query: (data: UpdateGeneralSensorRequestDTO) => ({
        url: `/sensor/general-sensor/${data.sensorId}/update`,
        method: "POST",
        body: { label: data.label },
      }),
      invalidatesTags: ["sensors"],
    }),
    viewSensors: builder.query<Sensor[], { page?: number, size?: number }>({
      query: ({ page, size }) => ({
        url: `/sensor/all`,
        method: "GET",
        params: {
          page,
          size
        },
      }),
      providesTags: ["sensors"],
    }),
    checkSensorAvailability: builder.mutation<{ isAvailable: boolean }, string>(
      {
        query: (serialId) => ({
          url: `sensor/unregistered-sensor/availability/view?serialKey=${serialId}`,
          method: "GET",
        }),
      }
    ),
  }),
});

export const {
  useAddSensorMutation,
  useUpdateSensorMutation,
  useViewSensorsQuery,
  useCheckSensorAvailabilityMutation,
} = sensorAPI;
