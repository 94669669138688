import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useAddDashboardMutation,
  useUpdateDashboardMutation,
} from "../../../redux/api/dashboard-api/dashboard-api";
import stack from "../../assets/images/stacks-white.svg";
import { Dashboard } from "../../interfaces/modals";
import Button from "../../ui-elements/button/button";
import IconButton from "../../ui-elements/icon-button/icon-button";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import TextAria from "../../ui-elements/text-aria/text-aria";
import TextField from "../../ui-elements/text-field/text-field";
import AddWidgetModal from "../add-widget-modal/add-widget-modal";
import EditWorkflowConfirmModal from "./edit-workflow-confirm-modal/edit-workflow-confirm-modal";
import styles from "./workflow-edit-side-bar.module.scss";

type Inputs = {
  name: string;
  description: string;
};

interface IProps {
  show: boolean;
  onClose: () => void;
  selectedDashboard: Dashboard;
}

const DashboardEditSideBar = (props: IProps) => {
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [formStep, setFormStep] = useState(0);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const [addDashboard, { isLoading: isLoadingAddDashboard }] =
    useAddDashboardMutation();
  const [updateWorkflow] = useUpdateDashboardMutation();

  useEffect(() => {
    if (props.selectedDashboard) {
      setValue("name", props.selectedDashboard.label);
      setValue("description", props.selectedDashboard.label || "");
    }
  }, [props.selectedDashboard, setValue]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (props.selectedDashboard.id) {
      setConfirmModal(true);
      return;
    } else {
      await addDashboard({
        label: data.name,
        additionalFields: {
          description: data.description,
        },
      })
        .unwrap()
        .then(() => {
          toast.success("Saved dashboard successfully");
          props.onClose();
          reset();
        })
        .catch(() => {
          toast.error("Failed to save the dashboard");
          reset();
        });
    }
  };

  const onUpdate: SubmitHandler<Inputs> = async (data) => {
    if (props.selectedDashboard.id) {
      await updateWorkflow({
        processId: props.selectedDashboard.id,
        name: data.name,
        description: data.description,
      })
        .unwrap()
        .then(() => {
          toast.success("Updated dashboard successfully");
          setConfirmModal(false);
          props.onClose();
        })
        .catch(() => {
          toast.error("Failed to update the dashboard");
        });
    }
  };

  return (
    <div
      className={styles.widgetMoreInfoContainer}
      style={props.show ? { right: "0" } : { right: "-559px" }}
    >
      <Container fluid>
        <Row className="align-items-center justify-content-between">
          <Col xs="auto">
            <IconButton
              background="#2F2A89"
              icon={stack}
              width={50}
              height={50}
              padding="10px"
              borderRadius={17}
            />
          </Col>
          <Col>
            <div className={styles.title}>
              {props.selectedDashboard.id ? `Edit` : `New`} Dashboard
            </div>
          </Col>
          <Col xs="auto">
            <MaterialIcon
              icon="close"
              className="cursor-pointer"
              size={35}
              onClick={() => {
                props.onClose();
                reset();
              }}
            />
          </Col>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mt-5 mx-1">
            <Col xs={12}>
              <TextField
                name="name"
                placeholder=""
                label="Dashboard Name"
                register={register("name", {
                  required: "Please enter a dashboard name",
                })}
                errors={errors}
              />
            </Col>
            <Col xs={12} className="mt-5">
              <TextAria
                label={"Description"}
                inputProps={{ ...register("description", { required: true }) }}
              />
              {errors.description && (
                <div className="error">Please enter a description</div>
              )}
            </Col>
          </Row>
          <Row className="mt-5 row-cols-auto justify-content-center">
            <Col>
              <Button
                text={props.selectedDashboard.id ? `Add New Widget` : `Cancel`}
                onClick={() => {
                  if (props.selectedDashboard.id) {
                    setShowForm(true);
                    setFormStep(0);
                  } else {
                    props.onClose();
                  }
                }}
                variant={props.selectedDashboard.id ? `Primary` : `Outline`}
                borderRadius={24}
                padding={`12px ${props.selectedDashboard.id ? `50px` : `80px`}`}
                type="button"
              />
            </Col>
            <Col>
              <Button
                type="submit"
                text="Save"
                variant={props.selectedDashboard.id ? `Outline` : `Primary`}
                borderRadius={24}
                padding={`12px ${props.selectedDashboard.id ? `80px` : `90px`}`}
                isLoading={isLoadingAddDashboard}
              />
            </Col>
          </Row>
        </form>
      </Container>
      <AddWidgetModal
        show={showForm}
        selectedWorkflowId={props.selectedDashboard.id}
        onClose={() => {
          setShowForm(false);
        }}
        formStep={formStep}
        setFormStep={setFormStep}
        setHideSidebarMenu={props.onClose}
      />
      <EditWorkflowConfirmModal
        show={showConfirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
        onConfirm={handleSubmit(onUpdate)}
      />
    </div>
  );
};

export default DashboardEditSideBar;
