import { Col, Container, Row } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import alert from "../../assets/images/alert.svg";
import IconButton from "../../ui-elements/icon-button/icon-button";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import styles from "./alert-side-menu.module.scss";

type MenuContextType = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

const AlertSideMenu = () => {
  const [showSideMenu, setShowSideMenu] =
    useOutletContext<MenuContextType>() ?? [false, () => {}];

  return (
    <Container
      fluid
      className={`${styles.alertSideMenuContainer}
    ${showSideMenu ? styles.show : styles.hide} me-0 ${
        showSideMenu ? "me-md-3" : "me-md-0"
      }
    `}
    >
      <Row className="align-items-center justify-content-between">
        <Col xs="auto">
          <IconButton
            background="#2F2A89"
            icon={alert}
            width={50}
            height={50}
            padding="10px"
            borderRadius={17}
          />
        </Col>
        <Col>
          <div className={styles.title}>Alerts</div>
        </Col>
        <Col xs="auto">
          <MaterialIcon
            icon="close"
            className="cursor-pointer"
            size={25}
            onClick={() => {
              setShowSideMenu(false);
            }}
          />
        </Col>
      </Row>
      <Row className={"mt-4"}>
        <Col xs={12} className={styles.item} onClick={() => {}}>
          <div className={`${styles.workflowName}`}>Recent Alerts</div>
        </Col>
        <Col xs={12} className={styles.item} onClick={() => {}}>
          <div className={`${styles.workflowName}`}>Manage Alerts</div>
        </Col>
      </Row>
    </Container>
  );
};

export default AlertSideMenu;
