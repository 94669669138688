import { Col, Row } from "react-bootstrap";
import icon02 from "../../assets/images/assignment-gray.svg";
import icon03 from "../../assets/images/chart_data-gray.svg";
import icon01 from "../../assets/images/stacks-white.svg";
import TabButton from "../../ui-elements/tab-button/tab-button";

const Tabs = () => {
  return (
    <Row className="row-cols-auto g-2 ">
      <Col>
        <TabButton icon={icon01} text="Coconut Mill" active />
      </Col>
      <Col>
        <TabButton icon={icon02} text="Reports" />
      </Col>
      <Col>
        <TabButton icon={icon03} text="Trends" />
      </Col>
    </Row>
  );
};

export default Tabs;
