import { Option } from "../../interfaces/modals";
import styles from "./select.module.scss";

interface IProps {
  label: string;
  value?: string;
  options: Option[];
  onSelect?: (date: { value: string; id: string }) => void;
  inputProps?: any;
  disabled?: boolean;
}

const Select = (props: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.label}>{props.label}</div>
      {props.value ? (
        <select
          className={`${styles.textField} ${
            props.disabled ? `cursor-disabled` : ``
          }`}
          value={props.value}
          onChange={(e) => {
            if (props.onSelect) {
              const [selected] = props.options.filter(
                (o) => o.id === e.target.value
              );
              if (selected) props.onSelect(selected);
            }
          }}
          disabled={props.disabled}
        >
          {props.options.map((o, index) => (
            <option key={index} value={o.id}>
              {o.value}
            </option>
          ))}
        </select>
      ) : (
        <select
          className={`${styles.textField} ${
            props.disabled ? `cursor-disabled` : ``
          }`}
          {...props.inputProps}
          disabled={props.disabled}
        >
          {props.options.map((o, index) => (
            <option key={index} value={o.id}>
              {o.value}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default Select;
