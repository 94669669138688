import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoginMutation } from "../../redux/api/auth-api/auth-api";
import loginImg from "../../shared/assets/images/login-image.svg";
import AuthenticateUserRequestDTO from "../../shared/interfaces/dtos/request-dtos/authenticate-user-request-dto";
import AuthenticateUserResponseDTO from "../../shared/interfaces/dtos/response-dtos/authenticate-user-response-dto";
import Button from "../../shared/ui-elements/button/button";
import CheckBox from "../../shared/ui-elements/check-box/check-box";
import MaterialIcon from "../../shared/ui-elements/material-icon/material-icon";
import TextField from "../../shared/ui-elements/text-field/text-field";
import { Pages } from "../routes";
import styles from "./login.module.scss";

const defaultFormValues: AuthenticateUserRequestDTO = {
  username: "",
  password: "",
  rememberMe: false,
};

const Login = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [login, { isLoading }] = useLoginMutation();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<AuthenticateUserRequestDTO>({
    defaultValues: { ...defaultFormValues },
  });

  const onSubmit: SubmitHandler<AuthenticateUserRequestDTO> = async (data) => {
    login(data)
      .unwrap()
      .then((res: AuthenticateUserResponseDTO) => {
        if (res.token) {
          navigate(Pages.dashboards);
        }
        toast.success("Login Successfully");
      })
      .catch(() => {
        toast.error("Login Unsuccessfully");
        reset();
      });
  };

  return (
    <Row className="align-content-center align-items-xl-center justify-content-center h-100 gap-4">
      <Col xs={12} xl="auto" className="text-center text-xl-start">
        <img className="img-fluid" src={loginImg} alt="login" />
      </Col>
      <Col xs={12} sm={11} md={10} lg={8} xl="auto" className="px-5 px-xl-0">
        <Row className="justify-content-start">
          <Col xs="auto" className={styles.text1}>
            Welcome
          </Col>
        </Row>
        <Row className="justify-content-start">
          <Col xs="auto" className={styles.text2}>
            Log in to Your Account
          </Col>
        </Row>
        <Row className="justify-content-start">
          <Col xs="auto" className={styles.text3}>
            Please enter your login credentials to continue
          </Col>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mt-4">
            <Col>
              <TextField
                name="username"
                placeholder="johndoe@iiot.com"
                label="Username or Email"
                register={register("username", {
                  required: "Incorrect Username or Email. Please try again",
                })}
                errors={errors}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <TextField
                name="password"
                icon={!showPassword ? "visibility_off" : "visibility"}
                placeholder="**********"
                onIconClick={() => setShowPassword(!showPassword)}
                label="Password"
                type={showPassword ? "text" : "password"}
                register={register("password", {
                  required: "Incorrect Password. Please try again",
                })}
                errors={errors}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <CheckBox
                label="Remember me"
                checked={false}
                onChange={(checked) => setValue("rememberMe", checked)}
              />
            </Col>
            <Col className="text-end">
              <Link to={""} className="text-link">
                Forgot password
              </Link>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button
                text="Login"
                variant="Primary"
                borderRadius={24}
                type="submit"
                padding="12px 70px"
                isLoading={isLoading}
              />
            </Col>
          </Row>
        </form>
        <Row className="mt-5 align-items-center justify-content-center">
          <Col xs="auto" className={`${styles.text4} pe-2`}>
            Don't have an account?
          </Col>
          <Col xs="auto" className={`${styles.text5} cursor-pointer pe-0`}>
            Sign Up
          </Col>
          <Col xs="auto" className={`${styles.text5} cursor-pointer`}>
            <MaterialIcon icon="arrow_forward" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Login;
