import { Col, Container, Row } from "react-bootstrap";
import device from "../../assets/images/device_thermostat.svg";
import styles from "./gauge.module.scss";

interface IProps {
  value: number;
}
const Gauge = (props: IProps) => {
  return (
    <div className={styles.gauge}>
      <div className={styles.gauge__body}>
        <div
          className={styles.gauge__fill}
          style={{
            transform: `rotate(${props.value / 200}turn`,
          }}
        ></div>
        <div className={styles.gauge__cover}>
          <Container fluid>
            <Row className={"align-items-center justify-content-center"}>
              <Col xs={"auto"} className={"p-0"}>
                <div className={styles.icon}>
                  <img src={device} alt={""} />
                </div>
              </Col>
              <Col xs={"auto"} className={"p-0"}>
                <label className={styles.value}>{props.value}</label>
                <label className={styles.unit}>&deg;C</label>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Gauge;
