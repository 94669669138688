import styles from "./avatar.module.scss";

interface IProps {
  image: any;
  onClick?: () => void;
}

const Avatar = (props: IProps) => {
  return (
    <div
      className={styles.avatar}
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
    >
      <img src={props.image} alt="avatar" />
    </div>
  );
};

export default Avatar;
