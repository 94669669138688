import { useRef } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingBar from "react-top-loading-bar";
import "./App.scss";
import { reduxStore } from "./redux/store/store";
import AppRoutes from "./screens/routes";

function App() {
  const ref = useRef(null);

  // ref.current.continuousStart()
  return (
    <Provider store={reduxStore}>
      <LoadingBar color={"#4466e3"} ref={ref} />
      <div className="App d-block d-md-flex">
        <AppRoutes />
        <ToastContainer theme="light" />
      </div>
    </Provider>
  );
}

export default App;
