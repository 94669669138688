import Hamburger from "hamburger-react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setToken } from "../../../redux/slices/auth-slice";
import { Pages } from "../../../screens/routes";
import reportActiveIcon from "../../assets/images/assignment-active.svg";
import reportInactiveIcon from "../../assets/images/assignment.svg";
import logo from "../../assets/images/bolt.svg";
import trendsInactiveIcon from "../../assets/images/chart_data.svg";
import trendsActiveIcon from "../../assets/images/chart_data_active.svg";
import logoutIcon from "../../assets/images/logout.svg";
import alertsActiveIcon from "../../assets/images/notifications-active.svg";
import alertsInactiveIcon from "../../assets/images/notifications.svg";
import sensorActiveIcon from "../../assets/images/sensor-nav-bar-active.svg";
import sensorInactiveIcon from "../../assets/images/sensor-nav-bar.svg";
import settingActiveIcon from "../../assets/images/settings-active.svg";
import settingInactiveIcon from "../../assets/images/settings.svg";
import workflowActiveIcon from "../../assets/images/stacks-active.svg";
import workflowInactiveIcon from "../../assets/images/stacks.svg";
import AppModal from "../../ui-elements/app-modal/app-modal";
import Avatar from "../../ui-elements/avatar/avatar";
import Button from "../../ui-elements/button/button";
import IconButton from "../../ui-elements/icon-button/icon-button";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import styles from "./nav-bar-mobile.module.scss";

interface IProps {
  onSideMenuHide: () => void;
}

const NavBarMobile = (props: IProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const logout = () => {
    localStorage.clear();
    dispatch(setToken(""));

    navigate(Pages.login);
  };

  return (
    <>
      <Container
        fluid
        className={`${styles.navbarMobile} d-block d-md-none p-3`}
        style={
          isOpen
            ? {
                borderBottom: "solid 1px #21273d",
              }
            : { borderBottom: "solid 1px #272e48" }
        }
      >
        <Row className="justify-content-between">
          <Col xs={"auto"} className="d-flex align-items-center">
            <IconButton icon={logo} width={40} height={40} />
          </Col>
          <Col xs={"auto"}>
            <Row className="align-items-center">
              <Col xs={"auto"} className="d-flex align-item-center">
                <Avatar
                  image={
                    "https://sm.ign.com/ign_nordic/cover/a/avatar-gen/avatar-generations_prsz.jpg"
                  }
                  onClick={() => {
                    navigate(Pages.profile);
                    setOpen(false);
                  }}
                />
              </Col>
              <Col xs={"auto"}>
                <Hamburger toggled={isOpen} toggle={setOpen} color="#2F2A89" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        style={isOpen ? { height: "450px" } : { height: "1px", padding: "0" }}
        className={`${styles.menu}  d-md-none ${
          isOpen ? "p-3 ps-4 py-5" : ""
        } `}
      >
        <Row>
          <Col
            xs={12}
            className="cursor-pointer"
            onClick={() => {
              navigate(Pages.dashboards);
              setOpen(false);
              pathname !== Pages.dashboards && props.onSideMenuHide();
            }}
          >
            <Row className="justify-content-start align-items-center">
              <Col xs={"auto"}>
                <IconButton
                  icon={
                    pathname === Pages.dashboards
                      ? workflowActiveIcon
                      : workflowInactiveIcon
                  }
                  width={40}
                  height={40}
                  padding="8px"
                  background={pathname === Pages.dashboards ? "#D5D4E7" : ""}
                  borderRadius={4}
                />
              </Col>
              <Col
                xs="auto"
                className={styles.title}
                style={
                  pathname === Pages.dashboards
                    ? { color: "#2F2A89" }
                    : { color: "#383941" }
                }
              >
                Workflows
              </Col>
              {pathname === Pages.dashboards && (
                <Col className="ps-0">
                  <MaterialIcon
                    icon="more_horiz"
                    color="#2F2A89"
                    onClick={props.onSideMenuHide}
                  />
                </Col>
              )}
            </Row>
          </Col>
          <Col
            xs={12}
            className="mt-3 cursor-pointer"
            onClick={() => {
              navigate(Pages.reports);
              setOpen(false);
            }}
          >
            <Row className="justify-content-start align-items-center">
              <Col xs={"auto"}>
                <IconButton
                  icon={
                    pathname === Pages.reports
                      ? reportActiveIcon
                      : reportInactiveIcon
                  }
                  width={40}
                  height={40}
                  padding="8px"
                  background={pathname === Pages.reports ? "#D5D4E7" : ""}
                  borderRadius={4}
                />
              </Col>
              <Col
                className={styles.title}
                xs={"auto"}
                style={
                  pathname === Pages.reports
                    ? { color: "#2F2A89" }
                    : { color: "#383941" }
                }
              >
                Reports
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            className="mt-3 cursor-pointer"
            onClick={() => {
              navigate(Pages.trends);
              setOpen(false);
              pathname !== Pages.trends && props.onSideMenuHide();
            }}
          >
            <Row className="justify-content-start align-items-center">
              <Col xs={"auto"}>
                <IconButton
                  icon={
                    pathname === Pages.trends
                      ? trendsActiveIcon
                      : trendsInactiveIcon
                  }
                  width={40}
                  height={40}
                  padding="8px"
                  background={pathname === Pages.trends ? "#D5D4E7" : ""}
                  borderRadius={4}
                />
              </Col>
              <Col
                className={styles.title}
                xs={"auto"}
                style={
                  pathname === Pages.trends
                    ? { color: "#2F2A89" }
                    : { color: "#383941" }
                }
              >
                Trends
              </Col>
              {pathname === Pages.trends && (
                <Col className="ps-0">
                  <MaterialIcon
                    icon="more_horiz"
                    color="#2F2A89"
                    onClick={props.onSideMenuHide}
                  />
                </Col>
              )}
            </Row>
          </Col>
          <Col
            xs={12}
            className="mt-3 cursor-pointer"
            onClick={() => {
              navigate(Pages.sensors);
              setOpen(false);
            }}
          >
            <Row className="justify-content-start align-items-center">
              <Col xs={"auto"}>
                <IconButton
                  icon={
                    pathname === Pages.sensors
                      ? sensorActiveIcon
                      : sensorInactiveIcon
                  }
                  width={40}
                  height={40}
                  padding="8px"
                  background={pathname === Pages.sensors ? "#D5D4E7" : ""}
                  borderRadius={4}
                />
              </Col>
              <Col
                className={styles.title}
                xs={"auto"}
                style={
                  pathname === Pages.sensors
                    ? { color: "#2F2A89" }
                    : { color: "#383941" }
                }
              >
                Sensors
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            className="mt-3 cursor-pointer"
            onClick={() => {
              navigate(Pages.alerts);
              setOpen(false);
              pathname !== Pages.alerts && props.onSideMenuHide();
            }}
          >
            <Row className="justify-content-start align-items-center">
              <Col xs={"auto"}>
                <IconButton
                  icon={
                    pathname === Pages.alerts
                      ? alertsActiveIcon
                      : alertsInactiveIcon
                  }
                  width={40}
                  height={40}
                  padding="8px"
                  background={pathname === Pages.alerts ? "#D5D4E7" : ""}
                  borderRadius={4}
                />
              </Col>
              <Col
                className={styles.title}
                xs={"auto"}
                style={
                  pathname === Pages.alerts
                    ? { color: "#2F2A89" }
                    : { color: "#383941" }
                }
              >
                Alerts
              </Col>
              {pathname === Pages.alerts && (
                <Col className="ps-0">
                  <MaterialIcon
                    icon="more_horiz"
                    color="#2F2A89"
                    onClick={props.onSideMenuHide}
                  />
                </Col>
              )}
            </Row>
          </Col>
          <Col
            xs={12}
            className="mt-3 cursor-pointer"
            onClick={() => {
              navigate(Pages.settings);
              setOpen(false);
            }}
          >
            <Row className="justify-content-start align-items-center">
              <Col xs={"auto"}>
                <IconButton
                  icon={
                    pathname === Pages.settings
                      ? settingActiveIcon
                      : settingInactiveIcon
                  }
                  width={40}
                  height={40}
                  padding="8px"
                  background={pathname === Pages.settings ? "#D5D4E7" : ""}
                  borderRadius={4}
                />
              </Col>
              <Col
                className={styles.title}
                xs={"auto"}
                style={
                  pathname === Pages.settings
                    ? { color: "#2F2A89" }
                    : { color: "#383941" }
                }
              >
                Settings
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            className="mt-3 cursor-pointer"
            onClick={() => setShowLogoutModal(true)}
          >
            <Row className="justify-content-start align-items-center">
              <Col xs={"auto"}>
                <IconButton
                  icon={logoutIcon}
                  width={40}
                  height={40}
                  padding="8px"
                  borderRadius={4}
                />
              </Col>
              <Col className={styles.title} xs={"auto"}>
                Logout
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <AppModal
        show={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
        width={650}
      >
        <Container>
          <Row>
            <Col className={styles.txt1}>Logout</Col>
          </Row>
          <Row className="mt-4">
            <Col className={styles.txt2}>
              Are you sure you want to logout now?
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs="auto">
              <Button
                text="Logout"
                variant="Primary"
                borderRadius={24}
                type="button"
                padding={`12px 70px`}
                onClick={logout}
              />
            </Col>
            <Col xs="auto">
              <Button
                text="Cancel"
                onClick={() => setShowLogoutModal(false)}
                variant="Outline"
                borderRadius={24}
                type="button"
                padding="12px 70px"
              />
            </Col>
          </Row>
        </Container>
      </AppModal>
    </>
  );
};

export default NavBarMobile;
