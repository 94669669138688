import closeIcon from "../../assets/images/close_small.svg";
import styles from "./app-modal.module.scss";

interface IProps {
  children: any;
  show: boolean;
  onClose?: () => void;
  width?: number;
}

const AppModal = (props: IProps) => {
  return (
    <div
      className={`${styles.wrapper} ${
        props.show
          ? `${styles["showing-modal"]}`
          : `${styles["not-showing-modal"]}`
      }`}
    >
      <div
        className={styles.model}
        style={
          props.width ? { width: props.width + "px" } : { width: "1000px" }
        }
      >
        <img
          className={styles.close}
          src={closeIcon}
          alt={""}
          width={18}
          height={18}
          onClick={() => {
            if (props.onClose) props.onClose();
          }}
        />
        <div className={`${props.show ? "d-block" : "d-none"}`}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default AppModal;
