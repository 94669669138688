import MaterialIcon from "../material-icon/material-icon";
import styles from "./icon-button.module.scss";

interface IProps {
  icon: string;
  width?: number;
  height?: number;
  margin?: string;
  padding?: string;
  background?: string;
  boxShadow?: string;
  borderRadius?: number;
  onClick?: () => void;
  isSideMenu?: boolean;
  onClickSideMenu?: () => void;
  isApplyWidth?: boolean;
  disabled?: boolean;
  isSideMenuOpen?: boolean;
}

const IconButton = (props: IProps) => {
  const { isApplyWidth = true } = props;

  return (
    <div
      className={`${styles.iconButton} ${
        props.disabled ? `cursor-disabled` : `cursor-pointer`
      }`}
      style={{
        width: props.width ? `${props.width}px` : "26px",
        height: props.height ? `${props.height}px` : "26px",
        margin: props.margin ? props.margin : "0px",
        background: props.background ? props.background : "transparent",
        padding: props.padding ? props.padding : "0px",
        borderRadius: props.borderRadius ? props.borderRadius + "px" : "0px",
        boxShadow: props.boxShadow ? props.boxShadow : "",
      }}
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
    >
      {props.isSideMenu && (
        <div
          style={{
            height: props.height ? `${props.height}px` : "26px",
            padding: props.padding ? props.padding : "0px",
            boxShadow: props.boxShadow ? props.boxShadow : "",
            width: props.isSideMenuOpen ? `22px` : `24px`,
          }}
          className={styles.sidMenuOpenIcon}
          onClick={() => {
            if (props.onClickSideMenu) props.onClickSideMenu();
          }}
        >
          <MaterialIcon
            icon={props.isSideMenuOpen ? "arrow_back_ios" : "arrow_forward_ios"}
            size={14}
            color="white"
          />
        </div>
      )}
      <img className={isApplyWidth ? styles.img : ``} src={props.icon} alt="" />
    </div>
  );
};

export default IconButton;
