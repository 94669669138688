import {Col, Container, Row} from "react-bootstrap";
import iconEdit from "../../assets/images/edit.svg";
import iconDelete from "../../assets/images/mdi_bin.svg";
import iconSensor from "../../assets/images/sensor-active.svg";
import {Sensor} from "../../interfaces/modals";
import styles from "./sensor-card.module.scss";

interface CardProps {
    sensor: Sensor;
    onClick: () => void;
}

const SensorCard = (props: CardProps) => {
    return (
        <Container fluid className={styles.cardContainer}>
            <Row className="row-cols-auto align-items-center justify-content-between">
                <Col style={{flex:1}}>
                    <Row>
                        <Col xs={"auto"}>
                            <img src={iconSensor} alt={""}/>
                        </Col>
                        <Col className={styles.text01}>{props.sensor.label}</Col>
                        <Col className={styles.text02}>{props.sensor.modelNumber}</Col>
                    </Row>
                </Col>
                <Col xs={12} md={"auto"} className={"pt-2 pt-md-0"}>
                    <Row className={"justify-content-end"}>
                        <Col xs={"auto"}>
                            <img
                                className="cursor-pointer"
                                src={iconEdit}
                                alt={""}
                                onClick={props.onClick}
                            />
                        </Col>
                        <Col xs={"auto"}>
                            <img src={iconDelete} alt={""}/>
                        </Col>
                    </Row>
                </Col>


            </Row>
        </Container>
    );
};

export default SensorCard;
