import CreateDashboardRequestDTO from "../../../shared/interfaces/dtos/request-dtos/create-dashboard-request-dto";
import UpdateWorkflowRequestDTO from "../../../shared/interfaces/dtos/request-dtos/update-dashboard-request-dto";
import UpdateWorkflowResponseDTO from "../../../shared/interfaces/dtos/response-dtos/update-dashboard-response-dto";
import { Dashboard } from "../../../shared/interfaces/modals";
import { rootApi } from "../api-manager";

// Define a service using a base URL and expected endpoints
export const dashboardAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addDashboard: builder.mutation({
      query: (dashboard: CreateDashboardRequestDTO) => ({
        url: "/dashboard",
        method: "POST",
        body: dashboard,
      }),
      invalidatesTags: ["dashboards"],
    }),
    updateDashboard: builder.mutation<
      UpdateWorkflowResponseDTO,
      UpdateWorkflowRequestDTO
    >({
      query: (data: UpdateWorkflowRequestDTO) => ({
        url: `/process/${data.processId}/update`,
        method: "POST",
        body: { name: data.name, description: data.description },
      }),
      invalidatesTags: ["dashboards"],
    }),
    viewDashboards: builder.query<Dashboard[], void>({
      query: () => "/dashboard/all",
      providesTags: ["dashboards"],
    }),
  }),
});

export const {
  useAddDashboardMutation,
  useUpdateDashboardMutation,
  useViewDashboardsQuery,
} = dashboardAPI;
