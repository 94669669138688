import { DetailedHTMLProps, InputHTMLAttributes, useMemo } from "react";
import { PuffLoader } from "react-spinners";
import styles from "./button.module.scss";

interface IProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  text: string;
  variant?: "Primary" | "Danger" | "Outline";
  borderRadius?: number;
  type?: "button" | "submit" | "reset";
  padding?: string;
  isLoading?: boolean;
}

const Button = (props: Readonly<IProps>) => {
  const { text, className, style, borderRadius, isLoading, ...rest } = props;

  const _variant = useMemo(() => {
    switch (props.variant) {
      case "Danger":
        return styles.btnDanger;
      case "Outline":
        return styles.btnOutline;
      default:
        return styles.btnPrimary;
    }
  }, [props.variant]);

  const _getButtonContent = () => {
    if (isLoading) {
      return <PuffLoader color={"white"} size={22} />;
    } else {
      return <>{text}</>;
    }
  };

  return (
    <button
      style={{
        ...style,
        borderRadius: borderRadius ?? style?.borderRadius,
        padding: props.padding ?? props.padding,
      }}
      className={`${styles.btn} ${_variant || ""} ${className} ${
        props.disabled ? `cursor-disabled` : ``
      }`}
      {...rest}
    >
      {_getButtonContent()}
    </button>
  );
};

export default Button;
