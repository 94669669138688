import { useState } from "react";
import styles from "./switch.module.scss";

interface IProps {
  value: string;
  onChange: (value: string, checked: boolean) => void;
  inputProps?: any;
}

const Switch = (props: IProps) => {
  const [checked, setChecked] = useState(false);
  return (
    <section style={{ display: "flex", alignItems: "center" }}>
      <span className={styles.switch}>
        <input
          type="checkbox"
          checked={checked}
          value={props.value}
          onChange={(e) => {
            setChecked(e.target.checked);
            props.onChange(e.target.value, e.target.checked);
          }}
        />
        <label
          onClick={() => {
            props.onChange(props.value, !checked);
            setChecked(!checked);
          }}
        ></label>
      </span>
    </section>
  );
};

export default Switch;
