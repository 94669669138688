import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useAddSensorMutation,
  useCheckSensorAvailabilityMutation,
  useUpdateSensorMutation,
} from "../../../redux/api/sensor-api/sensor-api";
import testIcon from "../../assets/images/lets-icons_check-fill.svg";
import { ReadingUnit } from "../../enums";
import { Sensor } from "../../interfaces/modals";
import AppModal from "../../ui-elements/app-modal/app-modal";
import Button from "../../ui-elements/button/button";
import TextField from "../../ui-elements/text-field/text-field";
import styles from "./add-update-sensor-modal.module.scss";

type Inputs = {
  label: string;
  modelNumber: string;
};

const defaultFormValues: Inputs = {
  label: "",
  modelNumber: "",
};

interface IProps {
  show: boolean;
  onClose: () => void;
  sensor?: Sensor;
}

const AddUpdateSensorModal = (props: IProps) => {
  const { show, sensor, onClose } = props;

  const [available, setAvailable] = useState<"yes" | "no" | "-">("-");

  const [addSensor, { isLoading: isLoadingAddSensor }] = useAddSensorMutation();
  const [updateSensor, { isLoading: isLoadingUpdateSensor }] =
    useUpdateSensorMutation();
  const [check] = useCheckSensorAvailabilityMutation();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: { ...defaultFormValues },
  });

  useEffect(() => {
    if (sensor) {
      setValue("label", sensor.label);
      setValue("modelNumber", sensor.modelNumber);
    }
  }, [sensor, setValue]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const x = {
      label: data.label,
      modelNumber: data.modelNumber,
      readingUnit: ReadingUnit.LITRE_PER_MINUTE,
    };

    if (sensor) {
      // await updateSensor({ label: data.label, id: sensor.id })
      //   .unwrap()
      //   .then(() => {
      //     toast.success("Update sensor succuss fully.");
      //     onClose();
      //   })
      //   .catch(() => {
      //     toast.error("Failed to save the sensor.");
      //   });
    } else {
      // await addSensor(x)
      //   .unwrap()
      //   .then(() => {
      //     toast.success("Add sensor succuss fully.");
      //     onClose();
      //     reset();
      //   })
      //   .catch((error) => {
      //     if (error.data.ovstErrorCode === "OVST_CONS_0001") {
      //       toast.error("Already registered sensor.");
      //       return;
      //     }
      //     toast.error("Failed to save the sensor.");
      //   });
    }
  };

  return (
    <AppModal
      show={show}
      onClose={() => {
        onClose();
        reset({ ...defaultFormValues });
        setAvailable("-");
      }}
      width={523}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Row className="justify-content-center mt-2">
            <Col xs="auto" className={styles.txt}>
              {sensor ? `Edit ${sensor?.label}` : `Add Sensor`}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              <TextField
                name="label"
                placeholder=""
                label="Label"
                register={register("label", {
                  required: "This field is required",
                })}
                errors={errors}
              />
            </Col>
            <Col xs={12} className={"mt-4"}>
              <TextField
                name="modelNumber"
                placeholder=""
                label="ID"
                register={register("modelNumber", {
                  required: "This field is required",
                })}
                errors={errors}
              />
            </Col>
            <Col
              xs={12}
              className={"mt-4 d-flex align-items-center"}
              style={{
                color: available === "yes" ? "#00C22B" : "red",
                fontSize: "20px",
                fontWeight: "400",
              }}
            >
              {available !== "-" && (
                <>
                  {available === "yes" ? (
                    <>
                      <img src={testIcon} alt="" />
                      Sensor test Successful
                    </>
                  ) : (
                    <>Sensor not available</>
                  )}
                </>
              )}
            </Col>
          </Row>
          <Row className="mt-5 justify-content-center">
            <Col xs="auto">
              <Button
                text="Test"
                onClick={async () => {
                  try {
                    const id = getValues("modelNumber");
                    const available = (await check(id).unwrap()).isAvailable;
                    setAvailable(available ? "yes" : "no");
                  } catch (err) {
                    toast.error("Sorry something went wrong.");
                  }
                }}
                variant="Outline"
                borderRadius={24}
                type="button"
                padding="12px 90px"
              />
            </Col>
            <Col xs="auto">
              <Button
                text={sensor ? "Save Changes" : "Add"}
                variant="Primary"
                borderRadius={24}
                type="submit"
                padding={`12px ${sensor ? `50` : `90`}px`}
                isLoading={isLoadingAddSensor || isLoadingUpdateSensor}
              />
            </Col>
          </Row>
        </Container>
      </form>
    </AppModal>
  );
};

export default AddUpdateSensorModal;
