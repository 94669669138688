import styles from "./text-aria.module.scss";

interface IProps {
  label: string;
  value?: string;
  inputProps?: any;
}

const TextAria = (props: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.label}>{props.label}</div>
      {props.value ? (
        <textarea className={styles.textField} value={props.value} />
      ) : (
        <textarea className={styles.textField} {...props.inputProps} />
      )}
    </div>
  );
};

export default TextAria;
