import MaterialIcon from "../material-icon/material-icon";
import styles from "./tab-button.module.scss";

interface IProps {
  icon: any;
  text: string;
  active?: boolean;
  onClick?: () => void;
}

const TabButton = (props: IProps) => {
  return (
    <div
      className={`${styles.tabButton} ${
        props.active ? styles.active : styles.inActive
      }`}
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
    >
      <img src={props.icon} alt="icon" width={20} height={18} />
      <label className="ps-1">{props.text}</label>
      <MaterialIcon
        icon="close"
        color={props.active ? `#F3F6FA` : `#69768B`}
        size={15}
      />
    </div>
  );
};

export default TabButton;
