import AuthenticateUserRequestDTO from "../../../shared/interfaces/dtos/request-dtos/authenticate-user-request-dto";
import AuthenticateUserResponseDTO from "../../../shared/interfaces/dtos/response-dtos/authenticate-user-response-dto";
import { setToken } from "../../slices/auth-slice";
import { rootApi } from "../api-manager";

export const authAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<
      AuthenticateUserResponseDTO,
      AuthenticateUserRequestDTO
    >({
      query: (data: AuthenticateUserRequestDTO) => ({
        url: "/authenticate",
        method: "POST",
        body: data,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          localStorage.setItem("token", res.data.token);
          dispatch(setToken(res.data.token));
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const { useLoginMutation } = authAPI;
