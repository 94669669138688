import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AddParameterButton from "../../shared/components/add-parameter-button/add-parameter-button";
import Chart from "../../shared/components/chart/chart";
import Parameter from "../../shared/components/parameter/parameter";
import Tabs from "../../shared/components/tabs/tabs";
import TrendsSideMenu from "../../shared/components/trends-side-menu/trends-side-menu";
import Button from "../../shared/ui-elements/button/button";
import Select from "../../shared/ui-elements/select/select";
import TextField from "../../shared/ui-elements/text-field/text-field";
import styles from "./manage-trends.module.scss";

interface DataEntry {
  time: string;
  value1: number;
  value2: number;
}

function generateRandomData(numEntries: number): DataEntry[] {
  const data: DataEntry[] = [];

  // Helper function to get a random time
  function getRandomTime(): string {
    const hours = Math.floor(Math.random() * 12) + 1; // Random hour between 1 and 12
    const minutes = Math.floor(Math.random() * 60); // Random minutes between 0 and 59
    const ampm = Math.random() > 0.5 ? "AM" : "PM"; // Random AM or PM

    return `${hours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
  }

  for (let i = 0; i < numEntries; i++) {
    data.push({
      time: getRandomTime(),
      value1: Math.floor(Math.random() * 100), // Random value between 0 and 99
      value2: Math.floor(Math.random() * 100), // Random value between 0 and 99
    });
  }

  return data;
}

const data = generateRandomData(50);

const ManageTrends = () => {
  const [chartType, setChartType] = useState<"Aria" | "Bar">("Aria");

  return (
    <Container fluid className={"p-0"}>
      <Row className="row-cols-auto gx-2">
        <Col>
          <Tabs />
        </Col>
      </Row>
      <div className={`${styles.trendsContainer} mt-4 d-block d-md-flex`}>
        <div className={styles.menu}>
          <TrendsSideMenu
            onEdit={() => {
              // setShowWorkflowEdit(true);
            }}
            setSelectedWorkflow={(id) => {
              // setSelectedWorkflowId(id);
            }}
          />
        </div>
        <Container fluid className={styles.content}>
          <Row className={`${styles.filterCard} p-0 py-4 p-md-4`}>
            <Col xs={12} className={styles.title}>
              Dryer Temperature vs Feed Rate
            </Col>
            <Col xs={12}>
              <Row className="mt-3">
                <Col xs={6}>
                  <TextField
                    placeholder=""
                    label="Template Name"
                    errors={Error}
                  />
                </Col>
                <Col xs={6}>
                  <Select
                    label={"Range"}
                    options={[
                      { id: "r-one", value: "100-200" },
                      { id: "r-one", value: "200-300" },
                      { id: "r-one", value: "400-500" },
                      { id: "r-one", value: "500-800" },
                    ]}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={6}>
                  <Select
                    label="Graph Type"
                    value={chartType}
                    onSelect={(data) => {
                      console.log(data);
                      setChartType(data.id as "Aria" | "Bar");
                    }}
                    options={[
                      { id: "Aria", value: "Aria" },
                      {
                        id: "Bar",
                        value: "Bar",
                      },
                    ]}
                  />
                </Col>
                <Col xs={6}>
                  <Select
                    label="Frequency"
                    options={[
                      { id: "r-one", value: "100" },
                      { id: "r-one", value: "200" },
                      { id: "r-one", value: "300" },
                      { id: "r-one", value: "400" },
                    ]}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12}>
                  <div className={styles.paraText}>Process Parameters</div>
                  <div className={"mt-3"}>
                    <Parameter
                      text={"Coconut kernel Feed Rate"}
                      color={"#CB12F9"}
                      isDisable={false}
                    />
                    <Parameter
                      text={"Dryer Temperature"}
                      color={"#0E9CFF"}
                      isDisable={false}
                    />
                    <Parameter
                      text={"Coconut kernel Feed Rate"}
                      color={"#CB12F9"}
                      isDisable={false}
                    />
                    <Parameter
                      text={"Dryer Temperature"}
                      color={"#0E9CFF"}
                      isDisable={false}
                    />
                    <Parameter
                      text={"Coconut kernel Feed Rate"}
                      color={"#CB12F9"}
                      isDisable={false}
                    />
                    <Parameter
                      text={"Dryer Temperature"}
                      color={"#0E9CFF"}
                      isDisable={false}
                    />
                    <Parameter
                      text={"Coconut kernel Feed Rate"}
                      color={"#CB12F9"}
                      isDisable={false}
                    />
                    <Parameter
                      text={"Dryer Temperature"}
                      color={"#0E9CFF"}
                      isDisable={false}
                    />
                    <AddParameterButton onAdd={() => {}} />
                  </div>
                </Col>
              </Row>
              <Row className="mt-5 justify-content-between g-2">
                <Col xs={12} md={"auto"}>
                  <Button
                    text="Save Template"
                    variant="Outline"
                    className="w-100"
                    borderRadius={24}
                    type="button"
                    padding="12px 50px"
                  />
                </Col>
                <Col xs={12} md={"auto"}>
                  <Button
                    text="Process"
                    variant="Primary"
                    className="w-100"
                    borderRadius={24}
                    type="button"
                    padding="12px 60px"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className={styles.chartCard}>
              <Chart data={data} chartType={chartType} />
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
};

export default ManageTrends;
