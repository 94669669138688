import { ReadingUnit } from "../enums";

export function getReadingTypes(readingUnit: ReadingUnit | undefined) {
  switch (readingUnit) {
    case ReadingUnit.CELSIUS:
      return <>&deg;C</>;
    case ReadingUnit.COUNT_PER_SECOND:
      return "Count/s";
    case ReadingUnit.LITRE:
      return "L";
    case ReadingUnit.LITRE_PER_MINUTE:
      return "L/min";
    case ReadingUnit.PASCAL:
      return "Pa";
    case ReadingUnit.KILOGRAM:
      return "Kg";
    default:
      return "";
  }
}
