import { formatNumberWithCommas } from ".";
import oilBarrel from "../assets/images/oil_barrel.svg";
import package_2 from "../assets/images/package_2.svg";
import WidgetAmount from "../components/widgets/amount/widget-amount";
import WidgetTank from "../components/widgets/tank/widget-tank";
import WidgetTemperature from "../components/widgets/temperature/widget-temperature";
import { WidgetType } from "../enums";
import { Widget } from "../interfaces/modals";

interface IProps {
  widgetType: WidgetType;
  widget?: Widget;
  onClick?: (selectedWidget: Widget, showMoreInfo: boolean) => void;
}

const WidgetTypes = (props: IProps) => {
  console.log("widget");
  console.log(props.widgetType);
  console.log(props.widget);

  switch (props.widgetType) {
    case WidgetType.WIDGET_TYPE_01:
      console.log("athule");
      return (
        <WidgetAmount
          title={props.widget?.label || "Incoming Coconut Count"}
          unit={props.widget?.dataSources[0].viewingUnit || "Coconuts"}
          icon={package_2}
          value={formatNumberWithCommas(props.widget?.id || 20256)}
          upload={formatNumberWithCommas(props.widget?.id || 35000)}
          download={formatNumberWithCommas(props.widget?.id || 85000)}
          onClick={() => {
            if (props.widget && props.onClick) {
              props.onClick(props.widget, true);
            }
          }}
        />
      );
    case WidgetType.WIDGET_TYPE_02:
      console.log("athule");
      return (
        <WidgetTemperature
          title={props.widget?.label || "Steam Bleacher Temperature"}
          value={formatNumberWithCommas(props.widget?.id || 23434)}
          onClick={() => {
            if (props.widget && props.onClick) {
              props.onClick(props.widget, true);
            }
          }}
        />
      );
    case WidgetType.WIDGET_TYPE_03:
      return (
        <WidgetTank
          title={props.widget?.label || "Oil Storage Tank"}
          icon={oilBarrel}
          value={formatNumberWithCommas(props.widget?.id || 6000)}
          unit={props.widget?.dataSources[0]?.viewingUnit || "Coconuts"}
          upload={formatNumberWithCommas(props.widget?.id || 1100)}
          download={formatNumberWithCommas(props.widget?.id || 3800)}
          onClick={() => {
            if (props.widget && props.onClick) {
              props.onClick(props.widget, true);
            }
          }}
        />
      );
    default:
      return null;
  }
};

export default WidgetTypes;
