import { Col, Container, Row } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import trendIcon from "../../assets/images/chart_data-white.svg";
import iconEdit from "../../assets/images/edit.svg";
import Button from "../../ui-elements/button/button";
import IconButton from "../../ui-elements/icon-button/icon-button";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import styles from "./trends-side-menu.module.scss";

type MenuContextType = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

interface IProps {
  onEdit: () => void;
  setSelectedWorkflow: (id: string) => void;
}

const TrendsSideMenu = (props: IProps) => {
  const [showSideMenu, setShowSideMenu] =
    useOutletContext<MenuContextType>() ?? [false, () => {}];
  return (
    <Container
      fluid
      className={`${styles.trendSideMenuContainer} ${
        showSideMenu ? styles.show : styles.hide
      } me-0 ${showSideMenu ? "me-md-3" : "me-md-0"}`}
    >
      <Row className={"align-items-center justify-content-between"}>
        <Col xs={"auto"}>
          <IconButton
            background="#2F2A89"
            icon={trendIcon}
            width={50}
            height={50}
            padding="10px"
            borderRadius={17}
          />
        </Col>
        <Col>
          <div className={styles.title}>Trend Analysis</div>
        </Col>
        <Col xs="auto">
          <MaterialIcon
            icon="close"
            className="cursor-pointer"
            size={25}
            onClick={() => setShowSideMenu(false)}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12} className={styles.item} onClick={() => {}}>
          <Row className="align-items-center">
            <Col className={`${styles.trendName}`}>
              Dryer Temperature vs Feed Rate
            </Col>
            <Col xs="auto">
              <img
                src={iconEdit}
                alt={""}
                onClick={() => {
                  props.onEdit();
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} className={styles.item} onClick={() => {}}>
          <Row className="align-items-center">
            <Col className={`${styles.trendName}`}>
              Feed Rate vs something else
            </Col>
            <Col xs="auto">
              <img
                src={iconEdit}
                alt={""}
                onClick={() => {
                  props.onEdit();
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4 justify-content-center">
        <Col xs="auto">
          <Button
            text="New Template"
            onClick={() => {}}
            variant="Primary"
            borderRadius={24}
            type="button"
            padding="12px 70px"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default TrendsSideMenu;
