import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { selectAuth } from "../../../redux/slices/auth-slice";
import { useAppSelector } from "../../../redux/store/store";
import { Pages } from "../../../screens/routes";

const PrivateRoute = () => {
  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.token) {
      navigate(Pages.login);
    }
  }, [auth.token, navigate]);

  if (auth.token) {
    return <Outlet />;
  }
};

export default PrivateRoute;
