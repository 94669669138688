import ViewMaterialsRequestDTO from "../../../shared/interfaces/dtos/request-dtos/view-materials-request-dto";
import { Material } from "../../../shared/interfaces/modals";
import { rootApi } from "../api-manager";

export const materialAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    viewMaterials: builder.query<Material[], void>({
      query: () => "/material/view",
      transformResponse: (response: {
        materials: ViewMaterialsRequestDTO[];
      }) => {
        return response.materials;
      },
    }),
  }),
});

export const { useViewMaterialsQuery } = materialAPI;
