import addIcon from "../../assets/images/ic_round-plus.svg";
import styles from "./add-parameter-button.module.scss";
interface IProps {
  isDisable?: boolean;
  onAdd: () => void;
}

const AddParameterButton = (props: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div>
          <img src={addIcon} alt="Add Icon" />
        </div>
        <div className={`${styles.text} ps-2`}>Add Parameter</div>
      </div>
    </div>
  );
};

export default AddParameterButton;
