import { validateInputTypeNumberRegex } from "./regex";

class Helper {
  public static roundTo2(num: number): string {
    if (typeof num === "number") {
      return num.toFixed(2);
    } else {
      return "0";
    }
  }

  public static validateInputTypeNumber(
    event: React.KeyboardEvent<HTMLInputElement>
  ) {
    const regex = validateInputTypeNumberRegex;
    const key = event.key;
    if (!regex.test(key) && event.keyCode !== 8) {
      event.preventDefault();
      return;
    }
  }
}

export default Helper;
