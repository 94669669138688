import { Col, Container, Row } from "react-bootstrap";
import iconEdit from "../../assets/images/edit.svg";
import iconDelete from "../../assets/images/mdi_bin.svg";
import iconSensor from "../../assets/images/sensor-white.svg";
import { Alert } from "../../interfaces/modals";
import IconButton from "../../ui-elements/icon-button/icon-button";
import styles from "./alert-card.module.scss";

interface CardProps {
  alert: Alert;
}

const AlertCard = (props: CardProps) => {
  return (
    <Container fluid className={styles.cardContainer}>
      <Row className="align-items-center justify-content-between">
        <Col xs={2}>
          <IconButton
            background="#2F2A89"
            icon={iconSensor}
            width={40}
            height={40}
            padding="6px"
            borderRadius={17}
          />
        </Col>
        <Col xs={3} className={styles.text01}>
          {props.alert.name}
        </Col>
        <Col xs={3} className={styles.text02}>
          {props.alert.communicationMedium}
        </Col>
        <Col xs={2} className={"d-flex justify-content-end"}>
          <img src={iconDelete} alt="Delete Icon" />
        </Col>
        <Col xs="auto" className={"d-flex justify-content-end"}>
          <img src={iconEdit} alt="Edit ICon" />
        </Col>
      </Row>
    </Container>
  );
};

export default AlertCard;
