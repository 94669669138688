import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useViewSensorsQuery } from "../../redux/api/sensor-api/sensor-api";
import AddUpdateSensorModal from "../../shared/components/add-update-sensor-modal/add-update-sensor-modal";
import SensorCard from "../../shared/components/sensor-card/sensor-card";
import Tabs from "../../shared/components/tabs/tabs";
import { Sensor } from "../../shared/interfaces/modals";
import Button from "../../shared/ui-elements/button/button";
import styles from "./manage-sensors.module.scss";

const ManageSensors = () => {
  const [showModal, setShowModal] = useState(false);
  const [sensor, setSensor] = useState<Sensor>();

  const { data: sensors } = useViewSensorsQuery({});

  return (
    <Container fluid className={"p-0"}>
      <Row>
        <Col xs={12}>
          <Tabs />
        </Col>
        <Col xs={12} className={`${styles.container} mt-4`}>
          <Row className="align-items-center">
            <Col className={styles.title}>Manage Sensors</Col>
            <Col xs="auto">
              <Button
                text="Add New Sensor"
                onClick={() => {
                  setShowModal(true);
                }}
                variant="Primary"
                borderRadius={24}
                padding="12px 30px"
                type="button"
              />
            </Col>
          </Row>
          <Row className="mt-2 g-4">
            {sensors?.map((s) => {
              return (
                <Col lg={6}>
                  <SensorCard
                    sensor={s}
                    onClick={() => {
                      setSensor(s);
                      setShowModal(true);
                    }}
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      <AddUpdateSensorModal
        show={showModal}
        onClose={() => setShowModal(false)}
        sensor={sensor}
      />
    </Container>
  );
};

export default ManageSensors;
