import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { IiotErrorCode } from "../../shared/enums/iiot-error-codes";
import { IHttpError } from "../../shared/interfaces/http-errror";
import { setToken } from "../slices/auth-slice";

export const rtkQueryErrorLogger: Middleware = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const error = action.payload as IHttpError;

    if (
      "data" in error &&
      typeof error.data === "object" &&
      error.data !== null
    ) {
      error.iiotErrorCode = (error.data as any).ovstErrorCode;
    } else {
      console.log("Error Code: undefined");
    }

    switch (error.status) {
      case 500:
        console.log(error);
        break;
      case 401:
        if (error.iiotErrorCode === IiotErrorCode.OVST_SEC_0001) {
          localStorage.clear();
          api.dispatch(setToken(""));
        }
        break;
      default:
        break;
    }
  }

  return next(action);
};
