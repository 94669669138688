import styles from "./oil-tank.module.scss";

interface IProps {
  value: number;
}

const OilTank = (props: IProps) => {
  return (
    <div className={styles.oilTank}>
      <div className={styles.tank}>
        <div
          className={styles.wave}
          style={{ height: `${props.value}%` }}
        ></div>
      </div>
    </div>
  );
};

export default OilTank;
