import { Col, Container, Row } from "react-bootstrap";
import styles from "./progress-bar.module.scss";

const ProgressBar = () => {
  return (
    <Container fluid className={styles.progressBarContainer}>
      <Row>
        <Col className={`p-0 ${styles.text}`}>5</Col>
        <Col className={`p-0 text-center ${styles.text}`}>50</Col>
        <Col className={`p-0 text-end ${styles.text}`}>120</Col>
      </Row>
      <Row className="mt-1">
        <Col className={"p-0"}>
          <div className={styles.progressBar}>
            <div className={styles.progress} style={{ width: "40%" }}></div>
          </div>
        </Col>
      </Row>
      <Row className={"mt-1"}>
        <Col className={`p-0 ${styles.text}`}>Min</Col>
        <Col className={`p-0 text-center ${styles.text}`}>Optimal</Col>
        <Col className={`p-0 text-end ${styles.text}`}>Max</Col>
      </Row>
    </Container>
  );
};

export default ProgressBar;
