import AddWidgetRequestDTO from "../../../shared/interfaces/dtos/request-dtos/add-widget-request-dto";
import UpdateWidgetRequestDTO from "../../../shared/interfaces/dtos/request-dtos/update-widget-request-dto";
import UpdateWidgetResponseDTO from "../../../shared/interfaces/dtos/response-dtos/update-widget-response-dto";
import { rootApi } from "../api-manager";

// Define a service using a base URL and expected endpoints
export const widgetAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addWidget: builder.mutation({
      query: (data: { sensor: AddWidgetRequestDTO; dashboardId: string }) => ({
        url: `/process/${data.dashboardId}/job/create`,
        method: "POST",
        body: data.sensor,
      }),
      invalidatesTags: ["dashboards"],
    }),
    updateWidget: builder.mutation<
      UpdateWidgetResponseDTO,
      UpdateWidgetRequestDTO
    >({
      query: (data: UpdateWidgetRequestDTO) => ({
        url: `/job/${data.widgetId}/update`,
        method: "POST",
        body: { name: data.name },
      }),
      invalidatesTags: ["dashboards"],
    }),
  }),
});

export const { useAddWidgetMutation, useUpdateWidgetMutation } = widgetAPI;
