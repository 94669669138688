import { useState } from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import NavBarMobile from "../shared/components/nav-bar-mobile/nav-bar-mobile";
import NavBar from "../shared/components/nav-bar/nav-bar";
const Layout = () => {
  const [showSideMenu, setShowSideMenu] = useState(true);

  return (
    <>
      <NavBar
        onSideMenuHide={() => setShowSideMenu((ps) => !ps)}
        showSideMenu={showSideMenu}
      />
      <NavBarMobile onSideMenuHide={() => setShowSideMenu(true)} />
      <Container fluid className="content py-4 ps-2 ms-0 ps-md-5 ms-md-5">
        <Outlet context={[showSideMenu, setShowSideMenu]} />
      </Container>
    </>
  );
};

export default Layout;
