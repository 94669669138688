import { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useViewAlertTemplateQuery } from "../../../redux/api/alert-api/alert-api";
import { useViewDashboardsQuery } from "../../../redux/api/dashboard-api/dashboard-api";
import { useViewMaterialsQuery } from "../../../redux/api/material-api/material-api";
import {
  useAddWidgetMutation,
  useUpdateWidgetMutation,
} from "../../../redux/api/widget-api/widget-api";
import { WidgetType } from "../../enums";
import { Widget } from "../../interfaces/modals";
import AppModal from "../../ui-elements/app-modal/app-modal";
import Button from "../../ui-elements/button/button";
import Select from "../../ui-elements/select/select";
import Switch from "../../ui-elements/switch/switch";
import TextField from "../../ui-elements/text-field/text-field";
import WidgetTypes from "../../utils/getWidgetTypes";
import AddUpdateSensorModal from "../add-update-sensor-modal/add-update-sensor-modal";
import styles from "./add-widget-modal.module.scss";

type Inputs = {
  widgetType: string;
  name: string;
  sensorId: string;
  maxSensorReading: number;
  minSensorReading: number;
  optSensorReading: number;
  materialId: string;
  preferredReadingUnit: string;
  alertId?: string;
  triggers?: number[];
};

interface IProps {
  show: boolean;
  onClose: () => void;
  selectedWorkflowId: string;
  selectedWidget?: Widget;
  formStep: number;
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
  setHideSidebarMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddWidgetModal = (props: IProps) => {
  const { formStep, setFormStep } = props;

  const [withAlert, setWithAlert] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showAddUpdateSensorModal, setShowAddUpdateSensorModal] =
    useState(false);
  const { data: materials } = useViewMaterialsQuery();
  const { data: alerts } = useViewAlertTemplateQuery();
  const { data: workflows } = useViewDashboardsQuery();
  const [addWidget] = useAddWidgetMutation();
  const [updateWidget] = useUpdateWidgetMutation();

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    getValues,
    reset,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      triggers: [],
    },
  });

  const sensorType = watch("widgetType") as unknown as WidgetType;

  const selectedWorkflow = useMemo(() => {
    const [workflow] =
      workflows?.filter((w) => w.id === props.selectedWorkflowId) || [];
    return workflow;
  }, [workflows, props.selectedWorkflowId]);

  useEffect(() => {
    if (props.selectedWidget) {
      setValue("name", props.selectedWidget.label);
      // setValue("maxSensorReading", props.selectedWidget.maxSensorReading);
      // setValue("minSensorReading", props.selectedWidget.minSensorReading);
      // setValue("optSensorReading", props.selectedWidget.optSensorReading);
      // setValue("materialId", props.selectedWidget.materialId);
      // setValue("sensorId", props.selectedWidget.sensorId);

      setDisabled(true);
    }
  }, [props.selectedWidget, setValue]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      if (selectedWorkflow?.id) {
        await addWidget({
          sensor: {
            name: data.name,
            sensorId: data.sensorId,
            maxSensorReading: data.maxSensorReading,
            minSensorReading: data.minSensorReading,
            optSensorReading: data.optSensorReading,
            materialId: data.materialId,
            preferredReadingUnit: data.preferredReadingUnit,
            alertId: withAlert ? data.alertId : undefined,
            triggers: withAlert ? data.triggers : undefined,
          },
          dashboardId: selectedWorkflow.id,
        }).unwrap();
        toast.success("Save Widget successfully");
        reset();
        props.onClose();
        props.setHideSidebarMenu && props.setHideSidebarMenu(false);
      } else {
        toast.error("Please select a workflow");
      }
    } catch (err) {
      toast.error("Failed to save the Widget");
      reset();
      setFormStep(0);
      props.onClose();
    }
  };

  const onUpdate: SubmitHandler<Inputs> = async (data) => {
    if (props.selectedWidget) {
      await updateWidget({
        widgetId: props.selectedWidget?.id,
        name: data.name,
      })
        .unwrap()
        .then(() => {
          props.onClose();
          props.setHideSidebarMenu && props.setHideSidebarMenu(false);
          toast.success("Widget update successfully");
        })
        .catch(() => {
          props.onClose();
          toast.error("Failed to update the Widget");
        });
    }
  };

  const next = async () => {
    if (formStep === 0) {
      const output = await trigger(["widgetType"], { shouldFocus: true });
      if (!output) {
        return;
      } else {
        setFormStep(1);
      }
    } else if (formStep === 1) {
      const output = await trigger(
        [
          "name",
          "materialId",
          "sensorId",
          "optSensorReading",
          "preferredReadingUnit",
          "maxSensorReading",
          "minSensorReading",
        ],
        { shouldFocus: true }
      );
      if (!output) {
        return;
      } else {
        setFormStep(2);
      }
    } else {
      if (withAlert) {
        const valid = await trigger(["alertId"], { shouldFocus: true });
        if (valid) {
          await handleSubmit(onSubmit);
        }
      } else {
        await handleSubmit(onSubmit);
      }
    }
  };

  const prev = () => {
    if (formStep > 0) {
      setFormStep((ps) => ps - 1);
    }
  };

  // const units = useMemo(() => {
  //   switch (sensorType) {
  //     case WidgetType.CounterSensor:
  //       var list01 = enumToObject(CountPerSecond, (s) => ({
  //         value: capitalizeFirstLetter(s),
  //         id: s,
  //       }));
  //       setValue("preferredReadingUnit", list01[0].id);
  //       return list01;
  //     case WidgetType.TemperatureSensor:
  //       const list02 = enumToObject(Celsius, (s) => ({
  //         value: capitalizeFirstLetter(s),
  //         id: s,
  //       }));
  //       setValue("preferredReadingUnit", list02[0].id);
  //       return list02;
  //     case WidgetType.PressureSensor:
  //       const list03 = enumToObject(Pascal, (s) => ({
  //         value: capitalizeFirstLetter(s),
  //         id: s,
  //       }));
  //       setValue("preferredReadingUnit", list03[0].id);
  //       return list03;
  //     case WidgetType.WeightSensor:
  //       const list04 = enumToObject(Kilogram, (s) => ({
  //         value: capitalizeFirstLetter(s),
  //         id: s,
  //       }));
  //       setValue("preferredReadingUnit", list04[0].id);
  //       return list04;
  //     default:
  //       const list = enumToObject(Litre, (s) => ({
  //         value: capitalizeFirstLetter(s),
  //         id: s,
  //       }));
  //       setValue("preferredReadingUnit", list[0].id);
  //       return list;
  //   }
  // }, [setValue, sensorType]);

  const triggers = watch("triggers", []);

  const handleSwitchChange = (value: number, isChecked: boolean) => {
    const currentTriggers = triggers || [];
    if (isChecked) {
      setValue("triggers", [...currentTriggers, value]);
    } else {
      setValue(
        "triggers",
        currentTriggers.filter((trigger) => trigger !== value)
      );
    }
  };

  return (
    <>
      <AppModal
        show={showAddUpdateSensorModal && formStep === 1 ? false : props.show}
        onClose={() => {
          if (!props.selectedWidget?.id) {
            reset();
          }
          setFormStep(0);
          props.onClose();
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {formStep === 0 && (
            <Container fluid>
              <Row className="justify-content-center mt-2">
                <Col xs="auto" className={styles.title}>
                  Add Widget
                </Col>
              </Row>
              <Row className="justify-content-center mt-2">
                <Col xs="auto" className={styles.description}>
                  Select the type of the widget you want to add. Each widget has
                  a different use case
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs={6}>
                  <WidgetTypes widgetType={sensorType} />
                </Col>
                <Col xs={6}>
                  {/* <Select
                    label="Widget Type"
                    options={[
                      { value: "Counter Widget", id: WidgetType.CounterSensor },
                      {
                        value: "Pressure Widget",
                        id: WidgetType.PressureSensor,
                      },
                      {
                        value: "Temperature Widget",
                        id: WidgetType.TemperatureSensor,
                      },
                      { value: "Weight Widget", id: WidgetType.WeightSensor },
                      { value: "Level Widget", id: WidgetType.LevelSensor },
                    ]}
                    inputProps={{
                      ...register("widgetType", { required: true }),
                    }}
                  /> */}
                  {errors.widgetType && (
                    <div className="error">This field is required</div>
                  )}
                  <Row className="mt-4">
                    <Col className={styles.txt01}>Description</Col>
                  </Row>
                  <Row>
                    <Col className={styles.txt02}>
                      Norem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc vulputate libero et velit interdum, ac aliquet odio
                      mattis. Class aptent taciti sociosqu ad litora torquent
                      per conubia nostra, per inceptos himenaeos. Curabitur
                      tempus urna at turpis condimentum lobortis. Ut commodo
                      efficitur neque.
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center mt-4">
                <Col xs="auto">
                  <Button
                    text="Cancel"
                    type="button"
                    variant="Outline"
                    borderRadius={24}
                    padding="12px 80px"
                    onClick={() => {
                      reset();
                      setFormStep(0);
                      props.onClose();
                    }}
                  />
                </Col>
                <Col xs="auto">
                  <Button
                    text="Continue"
                    onClick={() => {
                      next();
                    }}
                    variant="Primary"
                    borderRadius={24}
                    padding="12px 70px"
                    type="button"
                  />
                </Col>
              </Row>
            </Container>
          )}

          {formStep === 1 && (
            <Container fluid>
              <Row className="justify-content-center mt-2">
                <Col xs="auto" className={styles.title}>
                  Configure Widget
                </Col>
              </Row>
              <Row className="justify-content-center mt-2">
                <Col xs="auto" className={styles.description}>
                  Configure your the selected widget.
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className={styles.txt03}>Setup Widget</Col>
              </Row>
              <Row className="mt-3">
                <Col xs={6}>
                  <TextField
                    name="name"
                    placeholder=""
                    label="Label"
                    register={register("name", {
                      required: "This field is required",
                    })}
                    errors={errors}
                  />
                </Col>
                <Col xs={6} className="mt-4">
                  {/* <Select
                    label={"Unit"}
                    inputProps={{
                      ...register("preferredReadingUnit", { required: true }),
                    }}
                    options={units}
                    disabled={disabled}
                  /> */}
                  {errors.preferredReadingUnit && (
                    <div className="error">This field is required</div>
                  )}
                </Col>
                <Col xs={6} className="mt-4">
                  <TextField
                    name="minSensorReading"
                    placeholder=""
                    label="Minimum Value"
                    type="number"
                    register={register("minSensorReading", {
                      required: "This field is required",
                    })}
                    errors={errors}
                    disabled={disabled}
                  />
                </Col>
                <Col xs={6} className="mt-4">
                  <TextField
                    name="maxSensorReading"
                    placeholder=""
                    label="Maximum Value"
                    type="number"
                    register={register("maxSensorReading", {
                      required: "This field is required",
                    })}
                    errors={errors}
                    disabled={disabled}
                  />
                </Col>
                <Col xs={6} className="mt-4">
                  <TextField
                    name="optSensorReading"
                    placeholder=""
                    label="Optimal Value"
                    type="number"
                    register={register("optSensorReading", {
                      required: "This field is required",
                    })}
                    errors={errors}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center mt-4">
                <Col xs="auto">
                  <Button
                    text="Back"
                    type="button"
                    variant="Outline"
                    borderRadius={24}
                    padding="12px 80px"
                    onClick={() => {
                      prev();
                    }}
                    disabled={disabled}
                  />
                </Col>
                <Col xs="auto">
                  <Button
                    text={props.selectedWidget ? `Save` : `Continue`}
                    onClick={() => {
                      if (props.selectedWidget) {
                        handleSubmit(onUpdate)();
                      } else {
                        next();
                      }
                    }}
                    variant="Primary"
                    borderRadius={24}
                    padding="12px 70px"
                    type="button"
                  />
                </Col>
              </Row>
            </Container>
          )}

          {formStep === 2 && (
            <Container fluid>
              <Row className="justify-content-center mt-2">
                <Col xs="auto" className={styles.title}>
                  Configure Alerts
                </Col>
              </Row>
              <Row className="justify-content-center mt-2">
                <Col xs="auto" className={styles.description}>
                  Setup your alerts. The alerts will be sent based on the
                  trigger data.
                </Col>
              </Row>
              <Row className="justify-content-end mt-3 p-2">
                <Col xs="auto">
                  <Row className="align-items-center">
                    <Col>Enable Alerts</Col>
                    <Col xs="auto">
                      <Switch
                        value="alert-on"
                        onChange={(value, checked) => {
                          setWithAlert(checked);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3 p-2">
                <Col xs={6}>
                  <Select
                    label="Alert Template"
                    inputProps={{ ...register("alertId", { required: true }) }}
                    options={
                      alerts?.map((a) => ({
                        id: a.id,
                        value: a.name,
                      })) || []
                    }
                  />
                  {errors.widgetType && (
                    <div className="alertId">This field is required</div>
                  )}
                </Col>
                <Col xs={6}>
                  <TextField
                    placeholder=""
                    label="Another Field"
                    errors={Error}
                    isRequired={false}
                  />
                </Col>
                <Col xs={12} className="mt-3">
                  <div>Triggers</div>
                  <Row className={"mt-3"}>
                    <Col xs={"auto"}>
                      <Row className="align-items-center">
                        <Col xs={"auto"}>
                          Min ({getValues("minSensorReading")})
                        </Col>
                        <Col xs={"auto"}>
                          <Switch
                            value={getValues("minSensorReading") + ""}
                            onChange={(value, checked) => {
                              handleSwitchChange(+value, checked);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="auto">
                      <Row className="align-items-center">
                        <Col xs={"auto"}>
                          Max ({getValues("maxSensorReading")})
                        </Col>
                        <Col xs={"auto"}>
                          <Switch
                            value={getValues("maxSensorReading") + ""}
                            onChange={(value, checked) => {
                              handleSwitchChange(+value, checked);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs="auto">
                      <Row className="align-items-center">
                        <Col xs="auto">
                          Optimal ({getValues("optSensorReading")})
                        </Col>
                        <Col xs="auto">
                          <Switch
                            value={getValues("maxSensorReading") + ""}
                            onChange={(value, checked) => {
                              handleSwitchChange(+value, checked);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="auto">
                      <Row className="align-items-center">
                        <Col xs="auto">Custom</Col>
                        <Col xs="auto">
                          <Switch
                            value="40"
                            onChange={(value, checked) => {
                              // handleSwitchChange(+value, checked);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6} className={"mt-3"}>
                  <TextField
                    placeholder=""
                    label="Custom Value"
                    errors={Error}
                    isRequired={false}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center mt-4">
                <Col xs="auto">
                  <Button
                    text="Back"
                    type="button"
                    variant="Outline"
                    borderRadius={24}
                    padding="12px 80px"
                    onClick={() => {
                      prev();
                    }}
                  />
                </Col>
                <Col xs="auto">
                  <Button
                    text="Continue"
                    variant="Primary"
                    borderRadius={24}
                    padding="12px 70px"
                    type="submit"
                  />
                </Col>
              </Row>
            </Container>
          )}
        </form>
      </AppModal>
      <AddUpdateSensorModal
        show={showAddUpdateSensorModal}
        onClose={() => {
          setShowAddUpdateSensorModal(false);
        }}
      />
    </>
  );
};

export default AddWidgetModal;
