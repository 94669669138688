import icon from "../../assets/images/Group 1746.svg";
import iconClose from "../../assets/images/icon_close_white.svg";
import styles from "./parameter.module.scss";
interface IProps {
  text: string;
  color: string;
  isDisable: boolean;
  onClose?: () => void;
  onDisable?: (isDisable: boolean) => void;
}

const Parameter = (props: IProps) => {
  return (
    <div className={styles.container} style={{ backgroundColor: props.color }}>
      <div className={styles.content}>
        <div className={styles.text}>{props.text}</div>
        <div className={styles.icons}>
          <div className={styles.iconVisible}>
            <img src={icon} alt="" />
          </div>
          <div className={styles.iconClose}>
            <img src={iconClose} alt={""} width={20} height={20} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parameter;
