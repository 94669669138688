export enum ReadingUnit {
  CELSIUS = "CELSIUS",
  LITRE = "LITRE",
  PASCAL = "PASCAL",
  COUNT_PER_SECOND = "COUNT_PER_SECOND",
  LITRE_PER_MINUTE = "LITRE_PER_MINUTE",
  KILOGRAM = "KILOGRAM",
}

export enum CommunicationMedium {
  EMAIL,
  WHATSAPP,
}

export enum Celsius {
  CELSIUS,
  KELVIN,
  FAHRENHEIT,
}

export enum Litre {
  LITRE,
  MILLILITRE,
  GALLON,
}

export enum Pascal {
  PASCAL,
  KILOPASCAL,
  MEGAPASCAL,
}

export enum CountPerSecond {
  COUNT_PER_SECOND,
  COUNT_PER_MINUTE,
  COUNT_PER_HOUR,
  COUNT_PER_DAY,
}

export enum LitrePerMinute {
  LITRE_PER_MINUTE,
  LITRE_PER_SECOND,
  LITRE_PER_HOUR,
  LITRE_PER_DAY,
}

export enum Kilogram {
  KILOGRAM,
  GRAM,
  MILLIGRAM,
  TONNE,
}

export enum WidgetType {
  WIDGET_TYPE_01 ="WIDGET_TYPE_01",
  WIDGET_TYPE_02 = "WIDGET_TYPE_02",
  WIDGET_TYPE_03 = "WIDGET_TYPE_03",
}
