import AddAlertTempRequestDto from "../../../shared/interfaces/dtos/request-dtos/add-alert-request-dto";
import GetAlertTempsResponseDto from "../../../shared/interfaces/dtos/response-dtos/get-alert-response-dto";
import { Alert } from "../../../shared/interfaces/modals";
import { rootApi } from "../api-manager";

export const alertAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addAlertTemplate: builder.mutation({
      query: (alert: AddAlertTempRequestDto) => ({
        url: "/alert/create",
        method: "POST",
        body: alert,
      }),
      invalidatesTags: ["alerts"],
    }),
    viewAlertTemplate: builder.query<Alert[], void>({
      query: () => "/alert/view",
      providesTags: ["alerts"],
      transformResponse: (response: { alerts: GetAlertTempsResponseDto[] }) => {
        return response.alerts.map((gs) => ({
          id: gs.id,
          name: gs.name,
          communicationMedium: gs.communicationMedium,
          to: gs.to,
        }));
      },
    }),
  }),
});

export const { useAddAlertTemplateMutation, useViewAlertTemplateQuery } =
  alertAPI;
