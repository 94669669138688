export enum IiotErrorCode {
  OVST_SEC_0001 = "OVST_SEC_0001",
  OVST_SEC_0002 = "OVST_SEC_0002",
  OVST_APP_SEC_0001 = "OVST_APP_SEC_0001",
  OVST_APP_SEC_0002 = "OVST_APP_SEC_0002",
  OVST_SEC_0004 = "OVST_SEC_0004",
  OVST_SEC_0005 = "OVST_SEC_0005",
  NO_INTERNET_CONNECTION = "NO_INTERNET_CONNECTION",
  OVST_APP_SEC_0004 = "OVST_APP_SEC_0004",
}
